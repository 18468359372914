<template>
  <div>
    <base-card v-if="item == 8">
      <v-card-text>
        <v-card-text>
          <div class="mb-5"><span class="text-h6">Promotion</span></div>
          <v-row>
            <v-col cols="12" lg="6"
              ><v-btn
                small
                color="primary"
                @click="(title = 'Add'), openDialog()"
                dark
              >
                Add Promotion
              </v-btn></v-col
            >
            <v-col cols="12" lg="6"
              ><v-text-field
                class="mt-n5"
                v-model="search"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
              ></v-text-field
            ></v-col>
          </v-row>
          <v-data-table
            :headers="headers"
            :items="promotionList"
            :search="search"
            @click:row="handleClick"
          >
            <template v-slot:item.amount="{ item }">
              <div v-if="item.type == 0">{{ item.amount }}%</div>
              <div v-else>RM{{ item.amount }}</div>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card-text>
    </base-card>
    <v-dialog v-model="dialog" width="400">
      <base-card>
        <v-card-text class="mt-3">
          <div class="mb-5">
            <span class="text-h6">{{ title }} Promotion</span>
          </div>
          <v-text-field
            prepend-icon="mdi-sale"
            label="Promotion Name"
            v-model="pName"
            :error-messages="pNameErrors"
            required
            @input="$v.pName.$touch()"
            @blur="$v.pName.$touch()"
          ></v-text-field>

          <v-radio-group v-model="pType">
            <template v-slot:label>
              <div>Type</div>
            </template>
            <v-radio label="Percentage" value="0"></v-radio>
            <v-radio label="Amount" value="1"></v-radio>
            <v-radio label="Flexible" value="2"></v-radio>
          </v-radio-group>

          <v-text-field
            prepend-icon="mdi-percent-circle"
            suffix="%"
            label="Percentage"
            type="number"
            v-if="pType == 0"
            v-model="pPercentage"
            :error-messages="pPercentageErrors"
            required
            @input="$v.pPercentage.$touch()"
            @blur="$v.pPercentage.$touch()"
          ></v-text-field>

          <v-text-field
            prepend-icon="mdi-numeric"
            label="Amount"
            prefix="RM"
            type="number"
            v-if="pType == 1"
            v-model="pAmount"
            :error-messages="pAmountErrors"
            required
            @input="$v.pAmount.$touch()"
            @blur="$v.pAmount.$touch()"
          ></v-text-field>

          <v-text-field
            prepend-icon="mdi-numeric"
            label="Amount"
            prefix="RM"
            type="number"
            disabled
            v-if="pType == 2"
            v-model="pAmount"
            :error-messages="pAmountErrors"
            required
            @input="$v.pAmount.$touch()"
            @blur="$v.pAmount.$touch()"
          ></v-text-field>

          <v-select
            v-model="selectBranch"
            :items="branchList"
            multiple
            chips
            prepend-icon="mdi-store"
            item-text="name"
            item-value="branch_id"
            label="Select Branch"
            :error-messages="selectBranchErrors"
            required
            @input="$v.selectBranch.$touch()"
            @blur="$v.selectBranch.$touch()"
          >
            <template v-slot:prepend-item>
              <v-list-item ripple @click="toggleForBranch">
                <v-list-item-action>
                  <v-icon :color="selectBranch.length > 0 ? 'primary' : ''">
                    {{ iconForBranch }}
                  </v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title> Select All </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-divider class="mt-2"></v-divider>
            </template>
          </v-select>

          <v-radio-group v-model="specificCategory" v-if="pType != 2">
            <template v-slot:label>
              <div>Special Request</div>
            </template>
            <v-radio label="None" value="0"></v-radio>
            <v-radio label="One Category" value="1"></v-radio>
            <v-radio label="Multiple Category" value="2"></v-radio>
            <!-- <v-radio label="Multiple Product" value="3"></v-radio> -->
          </v-radio-group>

          <v-select
            class="mt-n3"
            v-if="specificCategory == 1 && pType != 2"
            v-model="selectCategory"
            prepend-icon="mdi-format-list-bulleted"
            :items="categoriesList"
            :error-messages="selectCategoryErrors"
            required
            @input="$v.selectCategory.$touch()"
            @blur="$v.selectCategory.$touch()"
            item-text="name"
            item-value="category_id"
            label="Categories"
          ></v-select>

          <v-combobox
            class="mt-n3"
            v-if="specificCategory == 2 && pType != 2"
            prepend-icon="mdi-format-list-bulleted"
            :items="categoriesList"
            v-model="selectMultipleCategory"
            label="Select Multiple Categories"
            :error-messages="selectMultipleCategoryErrors"
            required
            @input="$v.selectMultipleCategory.$touch()"
            @blur="$v.selectMultipleCategory.$touch()"
            item-text="name"
            item-value="category_id"
            return-object
            multiple
          ></v-combobox>

          <v-autocomplete
            class="mt-n3"
            v-if="specificCategory == 3 && pType != 2"
            prepend-icon="mdi-format-list-bulleted"
            v-model="selectMultipleProduct"
            :items="productList"
            :error-messages="selectMultipleProductErrors"
            required
            @input="$v.selectMultipleProduct.$touch()"
            @blur="$v.selectMultipleProduct.$touch()"
            label="Select Multiple Product"
            item-text="name"
            item-value="product_id"
            return-object
            multiple
          ></v-autocomplete>


          <v-divider class="my-3"></v-divider>
          <v-checkbox
            class="mt-n1"
            v-model="allDay"
            false-value="0"
            true-value="1"
            v-if="pType != 2"
            label="All Day"
          >
          </v-checkbox>
          <div class="mt-n8" v-if="allDay == 0">
            <v-row>
              <v-col
                ><v-menu
                  v-model="sdMenu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="sDate"
                      label="From"
                      prepend-icon="mdi-calendar"
                      readonly
                      :error-messages="sDateErrors"
                      required
                      @input="$v.sDate.$touch()"
                      @blur="$v.sDate.$touch()"
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="sDate"
                    @input="sdMenu = false"
                  ></v-date-picker> </v-menu></v-col
              ><v-col>
                <v-menu
                  v-model="edMenu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="eDate"
                      label="To"
                      prepend-icon="mdi-calendar"
                      readonly
                      :error-messages="eDateErrors"
                      required
                      @input="$v.eDate.$touch()"
                      @blur="$v.eDate.$touch()"
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="eDate"
                    :min="sDate"
                    @input="edMenu = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
          </div>

          <v-checkbox
            class="mt-n3"
            v-model="allTime"
            false-value="0"
            true-value="1"
            v-if="pType != 2"
            label="All Time"
          >
          </v-checkbox>

          <div class="mt-n8" v-if="allTime == 0">
            <v-row
              ><v-col>
                <v-menu
                  ref="sTimeRef"
                  v-model="stMenu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  :return-value.sync="sTime"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="sTime"
                      label="Start"
                      prepend-icon="mdi-clock-time-four-outline"
                      readonly
                      :error-messages="sTimeErrors"
                      required
                      @input="$v.sTime.$touch()"
                      @blur="$v.sTime.$touch()"
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-time-picker
                    v-if="stMenu"
                    v-model="sTime"
                    full-width
                    @click:minute="$refs.sTimeRef.save(sTime)"
                  ></v-time-picker>
                </v-menu> </v-col
              ><v-col>
                <v-menu
                  ref="eTimeRef"
                  v-model="etMenu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  :return-value.sync="eTime"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="eTime"
                      label="End"
                      prepend-icon="mdi-clock-time-four-outline"
                      readonly
                      :error-messages="eTimeErrors"
                      required
                      @input="$v.eTime.$touch()"
                      @blur="$v.eTime.$touch()"
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-time-picker
                    v-if="etMenu"
                    v-model="eTime"
                    :min="sTime"
                    full-width
                    @click:minute="$refs.eTimeRef.save(eTime)"
                  ></v-time-picker>
                </v-menu>
              </v-col>
            </v-row>
          </div>

          <v-checkbox
            class="mt-n3"
            v-model="autoApply"
            false-value="0"
            true-value="1"
            v-if="pType != 2"
            label="Auto apply"
          >
            <template v-slot:append>
              <v-tooltip left max-width="200">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon color="primary" dark v-bind="attrs" v-on="on">
                    mdi-information-outline
                  </v-icon>
                </template>
                <span
                  >This discount will auto apply on checkout in POS when this
                  field is checked</span
                >
              </v-tooltip>
            </template>
          </v-checkbox>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn
            v-if="title == 'Edit'"
            @click="dialogDelete = true"
            text
            icon
            color="red lighten-2"
          >
            <v-icon>mdi-delete</v-icon>
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            :loading="saveBtnLoad"
            @click="dialogAction()"
            text
          >
            save
          </v-btn>
        </v-card-actions>
      </base-card>
    </v-dialog>

    <v-dialog v-model="dialogDelete" max-width="400px">
      <v-card>
        <v-card-title class="text-subtitle-1"
          >Are you sure you want to delete this item?</v-card-title
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="dialogDelete = false"
            >Cancel</v-btn
          >
          <v-btn color="blue darken-1" @click="deletePromotion" text>OK</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar v-model="snackbar" :color="snackbarColor" :timeout="timeout">
      {{ text }}

      <template v-slot:action="{ attrs }">
        <v-btn dark icon v-bind="attrs" @click="snackbar = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>
<script>
import { BASEURL, BASESYNCURL } from "@/api/baseurl";
import axios from "axios";
import { watch } from "vue";
import { validationMixin } from "vuelidate";
import {
  required,
  between,
  sameAs,
  not,
  minValue,
  decimal,
} from "vuelidate/lib/validators";

export default {
  props: {
    item: {
      type: Number,
      default: "",
    },
  },
  mixins: [validationMixin],

  validations: {
    pName: { required },
    sDate: { required },
    eDate: { required, sameAs: not(sameAs("sDate")) },
    sTime: { required },
    eTime: { required, sameAs: not(sameAs("sTime")) },
    selectCategory: { required },
    selectMultipleCategory: {required},
    selectMultipleProduct : {required},
    pPercentage: { required, decimal, sameAs: not(sameAs("zero")) },
    pAmount: { required, decimal, sameAs: not(sameAs("zero")) },
    selectBranch: { required },
  },

  data: () => ({
    domain: BASEURL,
    syncDomain: BASESYNCURL,
    snackbar: false,
    text: "",
    zero: "0",
    snackbarColor: "",
    timeout: 1900,
    companyID: "",
    promotionList: [],
    pName: "",
    pType: "0",
    pAmount: "",
    pPercentage: "",
    branchList: [],
    selectBranch: "",
    selectPromotionID: "",
    title: "",
    dialog: false,
    search: "",
    autoApply: "0",
    allDay: "0",
    allTime: "0",
    sdMenu: false,
    rangeMenu: false,
    dates: ["2019-09-10", "2019-09-20"],
    saveBtnLoad: false,
    sDate: "",
    eDate: "",
    edMenu: false,
    sTime: null,
    stMenu: false,
    eTime: null,
    etMenu: false,
    specificCategory: "0",
    selectMultipleCategory: "",
    selectCategory: "",
    selectMultipleProduct: "",
    categoriesList: [],
    productList: [],
    defaultValue: false,
    dialogDelete: false,
    headers: [
      {
        text: "Name",
        align: "start",
        value: "name",
        sortable: false,
      },
      {
        text: "Value",
        align: "end",
        value: "amount",
        sortable: false,
      },
    ],
  }),

  watch: {
    pType() {
      if (this.pType == 2 && this.defaultValue == true) {
        this.pAmount = "0.00";
        this.allDay = "1";
        this.allTime = "1";
      } else if (
        (this.pType == 0 || this.pType == 1) &&
        this.defaultValue == true
      ) {
        this.pAmount = "";
        this.allDay = "0";
        this.allTime = "0";
      }
    },
  },

  computed: {
    pNameErrors() {
      const errors = [];
      if (!this.$v.pName.$dirty) return errors;
      !this.$v.pName.required && errors.push("Promotion name is required");
      return errors;
    },

    sDateErrors() {
      const errors = [];
      if (!this.$v.sDate.$dirty) return errors;
      !this.$v.sDate.required && errors.push("Start Date is required");
      return errors;
    },

    eDateErrors() {
      const errors = [];
      if (!this.$v.eDate.$dirty) return errors;
      !this.$v.eDate.required && errors.push("End Date is required");
      !this.$v.eDate.sameAs &&
        errors.push("End Date must different with start date");
      return errors;
    },
    sTimeErrors() {
      const errors = [];
      if (!this.$v.sTime.$dirty) return errors;
      !this.$v.sTime.required && errors.push("Start Time is required");
      return errors;
    },
    eTimeErrors() {
      const errors = [];
      if (!this.$v.eTime.$dirty) return errors;
      !this.$v.eTime.required && errors.push("End Time is required");
      !this.$v.eTime.sameAs &&
        errors.push("End Time must different with start time");
      return errors;
    },

    selectCategoryErrors() {
      const errors = [];
      if (!this.$v.selectCategory.$dirty) return errors;
      !this.$v.selectCategory.required && errors.push("Categories is required");
      return errors;
    },

    selectMultipleCategoryErrors() {
      const errors = [];
      if (!this.$v.selectMultipleCategory.$dirty) return errors;
      !this.$v.selectMultipleCategory.required && errors.push("Categories is required");
      return errors;
    },

    selectMultipleProductErrors() {
      const errors = [];
      if (!this.$v.selectMultipleProduct.$dirty) return errors;
      !this.$v.selectMultipleProduct.required && errors.push("Product is required");
      return errors;
    },

    pPercentageErrors() {
      const errors = [];
      if (!this.$v.pPercentage.$dirty) return errors;
      !this.$v.pPercentage.required &&
        errors.push("Promotion percentage is required");
      !this.$v.pPercentage.decimal &&
        errors.push("Promotion percentage must be integer or decimal");
      !this.$v.pPercentage.sameAs &&
        errors.push("Promotion percentage cannot be 0");
      return errors;
    },

    pAmountErrors() {
      const errors = [];
      if (!this.$v.pAmount.$dirty) return errors;
      !this.$v.pAmount.decimal &&
        errors.push("Promotion amount must be integer or decimal");
      !this.$v.pAmount.required && errors.push("Promotion amount is required");
      !this.$v.pAmount.sameAs && errors.push("Promotion amount  cannot be 0");
      return errors;
    },

    selectBranchErrors() {
      const errors = [];
      if (!this.$v.selectBranch.$dirty) return errors;
      !this.$v.selectBranch.required &&
        errors.push("Please select the branch for the dining option");
      return errors;
    },
    getAllBranchID() {
      var array = [];
      for (let i = 0; i < this.branchList.length; i++) {
        array.push(this.branchList[i].branch_id);
      }
      return array;
    },

    selectAllBranch() {
      return this.selectBranch.length === this.getAllBranchID.length;
    },

    selectSomeBranch() {
      return this.selectBranch.length > 0 && !this.selectAllBranch;
    },

    iconForBranch() {
      if (this.selectAllBranch) return "mdi-close-box";
      if (this.selectSomeBranch) return "mdi-minus-box";
      return "mdi-checkbox-blank-outline";
    },
  },

  created() {
    var CryptoJS = require("crypto-js");
    var x = localStorage.getItem("userInfo");
    // Decrypt
    var bytes = CryptoJS.AES.decrypt(x, "secret key 123");
    var decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    this.companyID = decryptedData.company_id;
    this.getAllPromotion();
    this.getAllProductList();

  },
  methods: {
    getAllPromotion() {
      const params = new URLSearchParams();
      params.append("getAllPromotion", "done");
      params.append("company_id", this.companyID);

      axios({
        method: "post",
        url: this.domain + "/promotion/index.php",
        data: params,
      })
        .then((response) => {
          console.log(response);
          if (response.data.status == "1") {
            this.promotionList = response.data.promotion;
          } else {
            this.promotionList = [];
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getAllCategories() {
      const params = new URLSearchParams();
      params.append("getAllCategoriesForPromotion", "done");
      params.append("company_id", this.companyID);

      axios({
        method: "post",
        url: this.domain + "/categories/index.php",
        data: params,
      })
        .then((response) => {
          console.log(response);
          if (response.data.status == "1") {
            this.categoriesList = response.data.categories;
          } else {
            this.categoriesList = [];
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    getCompanyBranch() {
      const params = new URLSearchParams();
      params.append("getCompanyBranch", "done");
      params.append("company_id", this.companyID);

      axios({
        method: "post",
        url: this.domain + "/branch/index.php",
        data: params,
      })
        .then((response) => {
          console.log(response);
          if (response.data.status == "1") {
            this.branchList = response.data.branch;
          } else {
            console.log("no list get");
            this.branchList = [];
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    toggleForBranch() {
      this.$nextTick(() => {
        if (this.selectAllBranch) {
          this.selectBranch = [];
        } else {
          this.selectBranch = this.getAllBranchID.slice();
        }
      });
    },

    sendNotication(branch_id) {
      const params = new URLSearchParams();
      params.append("send_notification", "done");
      params.append("branch_id", branch_id);
      params.append("type", "1");

      axios({
        method: "post",
        url: this.syncDomain + "/sync/index.php",
        data: params,
      })
        .then((response) => {
          console.log(response);
          if (response.data.status == "1") {
            console.log("Send Notification successfully");
          } else {
            console.log("Send Notification fail");
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    getBranchForNotification(promotion_id) {
      const params = new URLSearchParams();
      params.append("getPromotionBranchForNotification", "done");
      params.append("promotion_id", promotion_id);

      axios({
        method: "post",
        url: this.domain + "/promotion/index.php",
        data: params,
      })
        .then((response) => {
          console.log(response);
          if (response.data.status == "1") {
            var branchList = response.data.promotion;
            for (let i = 0; i < branchList.length; i++) {
              this.sendNotication(branchList[i]);
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    deletePromotion() {
      const params = new URLSearchParams();
      params.append("deletePromotion", "done");
      params.append("promotion_id", this.selectPromotionID);
      axios({
        method: "post",
        url: this.domain + "/promotion/index.php",
        data: params,
      })
        .then((response) => {
          console.log(response);
          if (response.data.status == "1") {
            this.dialog = false;
            this.text = "Successfully delete";
            this.snackbar = true;
            this.snackbarColor = "success";
            this.dialogDelete = false;
            this.getAllPromotion();
            this.getBranchForNotification(this.selectPromotionID);
          } else {
            this.text = "Please try again later";
            this.snackbar = true;
            this.snackbarColor = "error";
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    dialogAction() {
      if (this.title == "Add") {
        this.$v.$touch();
        if (this.specificCategory == "1" && this.selectCategory == "") {
          this.text = "Please select the category";
          this.snackbar = true;
          this.snackbarColor = "error";
          return;
        }

        if (this.specificCategory == "2" && this.selectMultipleCategory == "") {
          this.text = "Please select the category";
          this.snackbar = true;
          this.snackbarColor = "error";
          return;
        }

        if (this.specificCategory == "3" && this.selectMultipleProduct == "") {
          this.text = "Please select the product";
          this.snackbar = true;
          this.snackbarColor = "error";
          return;
        }

        if (this.allDay == "0" && (this.sDate == "" || this.eDate == "")) {
          this.text = "Please fill in the promotion date";
          this.snackbar = true;
          this.snackbarColor = "error";
          return;
        }
        if (this.allTime == "0" && (this.sTime == "" || this.eTime == "")) {
          this.text = "Please fill in the promotion time";
          this.snackbar = true;
          this.snackbarColor = "error";
          return;
        }
        if (this.allDay == "0" && this.$v.eDate.$invalid) {
          this.text =
            "Please check promotion day. Make sure start and end is different";
          this.snackbar = true;
          this.snackbarColor = "error";
          return;
        }

        if (this.allTime == "0" && this.$v.eTime.$invalid) {
          this.text =
            "Please check promotion time. Make sure start and end is different";
          this.snackbar = true;
          this.snackbarColor = "error";
          return;
        }
        if (
          !this.$v.pName.$invalid &&
          !this.$v.selectBranch.$invalid &&
          (!this.$v.pPercentage.$invalid || !this.$v.pAmount.$invalid)
        ) {
          this.saveBtnLoad = true;
          const params = new URLSearchParams();
          params.append("addSpecialPromotion", "done");
          params.append("company_id", this.companyID);
          params.append("array_branch_id", JSON.stringify(this.selectBranch));
          params.append("name", this.pName);
          params.append("type", this.pType);
          params.append(
            "amount",
            this.pType == 0 ? this.pPercentage : this.pAmount
          );
          params.append("sDate", this.sDate);
          params.append("eDate", this.eDate);
          params.append("sTime", this.sTime);
          params.append("eTime", this.eTime);
          params.append("autoApply", this.autoApply);
          params.append("allDay", this.allDay);
          params.append("allTime", this.allTime);
          params.append("specificCategory", this.specificCategory);
          params.append("selectCategory", this.selectCategory);
          params.append("selectMultipleCategory",  Array.isArray(this.selectMultipleCategory) ? JSON.stringify(this.selectMultipleCategory): "[]");
          params.append("selectMultipleProduct",  Array.isArray(this.selectMultipleProduct) ? JSON.stringify(this.selectMultipleProduct):"[]");

          axios({
            method: "post",
            url: this.domain + "/promotion/index.php",
            data: params,
          })
            .then((response) => {
              console.log(response);
              if (response.data.status == "1") {
                this.dialog = false;
                this.text = "Successfully add";
                this.snackbar = true;
                this.snackbarColor = "success";
                this.getAllPromotion();
                for (let i = 0; i < this.selectBranch.length; i++) {
                  this.sendNotication(this.selectBranch[i]);
                }
              } else {
                this.text = "Please try again later";
                this.snackbar = true;
                this.snackbarColor = "error";
                this.saveBtnLoad = false;
              }
            })
            .catch((error) => {
              console.log(error);
            });
        }
      } else {
        this.$v.$touch();
        if (this.specificCategory == "1" && isNaN(this.selectCategory) == true) {
          this.text = "Please select the category";
          this.snackbar = true;
          this.snackbarColor = "error";
          return;
        }
        if (this.specificCategory == "2" && this.selectMultipleCategory == "") {
          this.text = "Please select the category";
          this.snackbar = true;
          this.snackbarColor = "error";
          return;
        }

        if (this.specificCategory == "3" && this.selectMultipleProduct == "") {
          this.text = "Please select the product";
          this.snackbar = true;
          this.snackbarColor = "error";
          return;
        }
        if (this.allDay == "0" && (this.sDate == "" || this.eDate == "")) {
          this.text = "Please fill in the promotion date";
          this.snackbar = true;
          this.snackbarColor = "error";
          return;
        }
        if (this.allTime == "0" && (this.sTime == "" || this.eTime == "")) {
          this.text = "Please fill in the promotion time";
          this.snackbar = true;
          this.snackbarColor = "error";
          return;
        }
        if (this.allDay == "0" && this.$v.eDate.$invalid) {
          this.text =
            "Please check promotion day. Make sure start and end is different";
          this.snackbar = true;
          this.snackbarColor = "error";
          return;
        }

        if (this.allTime == "0" && this.$v.eTime.$invalid) {
          this.text =
            "Please check promotion time. Make sure start and end is different";
          this.snackbar = true;
          this.snackbarColor = "error";
          return;
        }
        if (
          !this.$v.pName.$invalid &&
          !this.$v.selectBranch.$invalid &&
          (!this.$v.pPercentage.$invalid || !this.$v.pAmount.$invalid)
        ) {
          this.saveBtnLoad = true;
          const params = new URLSearchParams();
          params.append("editSpecialPromotion", "done");
          params.append("array_branch_id", JSON.stringify(this.selectBranch));
          params.append("name", this.pName);
          params.append("type", this.pType);
          params.append(
            "amount",
            this.pType == 0 ? this.pPercentage : this.pAmount
          );
          params.append("sDate", this.sDate);
          params.append("eDate", this.eDate);
          params.append("sTime", this.sTime);
          params.append("eTime", this.eTime);
          params.append("autoApply", this.autoApply);
          params.append("allDay", this.allDay);
          params.append("allTime", this.allTime);
          params.append("specificCategory", this.specificCategory);
          params.append("selectCategory", this.selectCategory);
          params.append("promotion_id", this.selectPromotionID);
          params.append("selectMultipleCategory", Array.isArray(this.selectMultipleCategory) ? JSON.stringify(this.selectMultipleCategory): "[]");
          params.append("selectMultipleProduct", Array.isArray(this.selectMultipleProduct) ? JSON.stringify(this.selectMultipleProduct):"[]");


          axios({
            method: "post",
            url: this.domain + "/promotion/index.php",
            data: params,
          })
            .then((response) => {
              console.log(response);
              if (response.data.status == "1") {
                this.dialog = false;
                this.text = "Successfully edit";
                this.snackbar = true;
                this.snackbarColor = "success";
                this.getAllPromotion();
                for (let i = 0; i < this.selectBranch.length; i++) {
                  this.sendNotication(this.selectBranch[i]);
                }
              } else {
                this.text = "Please try again later";
                this.snackbar = true;
                this.snackbarColor = "error";
                this.saveBtnLoad = false;
              }
            })
            .catch((error) => {
              console.log(error);
            });
        }
      }
    },

    handleClick(item) {
      this.defaultValue = false;
      this.pType = String(item.type);
      this.allDay = item.all_day;
      this.allTime = item.all_time;
      this.pName = item.name;
      if (item.type == 0) {
        this.pPercentage = item.amount;
      } else if (item.type == 1 || item.type == 2) {
        this.pAmount = item.amount;
      }
      this.autoApply = item.auto_apply;
      this.sDate = item.sdate;
      this.eDate = item.edate;
      this.sTime = item.stime;
      this.eTime = item.etime;
      this.specificCategory = item.specific_category;
      this.selectCategory = parseInt(item.category_id);
      this.selectMultipleCategory =  JSON.parse(item.multiple_category) 
      this.selectMultipleProduct = JSON.parse(item.multiple_product)
      this.selectPromotionID = item.promotion_id;
      this.getPromotionBranch();
      this.title = "Edit";
      this.openDialog();
    },

    getPromotionBranch() {
      const params = new URLSearchParams();
      params.append("getPromotionBranch", "done");
      params.append("promotion_id", this.selectPromotionID);

      axios({
        method: "post",
        url: this.domain + "/promotion/index.php",
        data: params,
      })
        .then((response) => {
          console.log(response);
          if (response.data.status == "1") {
            this.selectBranch = response.data.promotion;
          } else {
            console.log("no list get");
            this.selectBranch = "";
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },


    getAllProductList() {
      const params = new URLSearchParams();
      params.append("getAllProductForPromotion", "done");
      params.append("company_id", this.companyID);
      axios({
        method: "post",
        url: this.domain + "/product/index.php",
        data: params,
      })
        .then((response) => {
          console.log(response);
          if (response.data.status == "1") {
            this.productList = response.data.product;
          } else {
            this.productList = [];
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    openDialog() {
      this.saveBtnLoad = false;
      this.getCompanyBranch();
      this.getAllCategories();
      if (this.title == "Add") {
        this.defaultValue = true;
        this.$v.$reset();
        this.pName = "";
        this.pType = "0";
        this.pAmount = "";
        this.pPercentage = "";
        this.selectBranch = "";
        this.autoApply = "0";
        this.allDay = "0";
        this.allTime = "0";
        this.sDate = "";
        this.eDate = "";
        this.sTime = "";
        this.eTime = "";
        this.specificCategory = "0";
        this.selectCategory = "";
        this.selectMultipleCategory = "";
        this.selectMultipleProduct = "";
        this.dialog = true;
      } else {
        this.defaultValue = false;
        this.dialog = true;
      }
    },
  },
};
</script>
<style>
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}
</style>